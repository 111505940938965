import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalClose } from '../Modal';
import Title from '../Title';

import {
  ModalContentStyled,
  Header,
  Footer,
  SaveButton,
} from './EditDetailsModal.style';

const EditDetailsModal = ({
  children,
  onClose,
  onSave,
  open,
  titleDictionaryItem,
}) => (
  <Modal
    active={open}
    overlay
  >
    <ModalContentStyled>
      <ModalClose type="Button" onClick={onClose} />
      <Header>
        <Title tag={3} size={2} dictionary={titleDictionaryItem} />
      </Header>
      {children}
      <Footer>
        <SaveButton size="small" type="Button" onClick={onSave}>Update Details</SaveButton>
      </Footer>
    </ModalContentStyled>
  </Modal>
);

EditDetailsModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  titleDictionaryItem: PropTypes.string,
};

EditDetailsModal.defaultProps = {
  children: null,
  onClose: undefined,
  onSave: undefined,
  open: false,
  titleDictionaryItem: PropTypes.string,
};

export default EditDetailsModal;
