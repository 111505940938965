import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { DayPicker, MonthPicker, YearPicker } from 'react-dropdown-date';
import { padStart } from 'lodash';
import { Query } from 'react-apollo';

import { dictionaryItem } from '../../../../hocs/withDictionary';
import {
  MessageWarning,
} from './AddJointMember.style';
import { Input, Select } from '../../../ui/Form';
import {
  Input as InputNumberStyled,
  Select as SelectCountryPrefixStyled,
} from '../Party/ContactDetailsContentBlock.styles';
import { EditDetailsField, EditDetailsFields } from '../../../ui/EditDetailsModal';
import IbePropTypes from '../../../../IbePropTypes';
import GET_CONFIGURATION from '../../../../config/graphql/getConfiguration';
import { parseDateString } from './helpers';
import { getAge } from '../../../Guests/guestHelpers';

// control names, also used for validation errors hmmmm
export const ADD_MEMBER_ALL = 'AddMemberAll'; // this is the only const used for validation only
export const ADD_MEMBER_TITLE = 'AddMemberTitle';
export const ADD_MEMBER_FIRST_NAME = 'AddMemberFirstName';
export const ADD_MEMBER_LAST_NAME = 'AddMemberLastName';
export const ADD_MEMBER_DATE_OF_BIRTH_YEAR = 'AddMemberDateOfBirthYear';
export const ADD_MEMBER_DATE_OF_BIRTH_MONTH = 'AddMemberDateOfBirthMonth';
export const ADD_MEMBER_DATE_OF_BIRTH_DAY = 'AddMemberDateOfBirthDay';
export const ADD_MEMBER_COUNTRY_PREFIX = 'AddMemberCountryPrefix';
export const ADD_MEMBER_TELEPHONE_NUMBER = 'AddMemberTelephoneNumber';
export const ADD_MEMBER_AGE = 'AddMemberAge';

const propTypes = {
  hideDob: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  isAmend: PropTypes.bool,
  member: PropTypes.shape(IbePropTypes.membershipMember),
  updateMember: PropTypes.func.isRequired,
  addErrors: PropTypes.func.isRequired,
  removeErrors: PropTypes.func.isRequired,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
};

const defaultProps = {
  isAmend: false,
  isOpen: false,
  member: undefined,
  requiredFields: [],
  hiddenFields: [],
};

const AddMemberForm = forwardRef((props, ref) => {
  const { year, month, day } = parseDateString(props.member?.dateOfBirth);
  const formRef = useRef();
  const errorKey = props.isAmend ? 'AmendForm' : 'Membership';

  const errorMessages = {
    firstName: dictionaryItem(errorKey, 'FirstName', 'Error'),
    surname: dictionaryItem(errorKey, 'LastName', 'Error'),
    year: dictionaryItem(errorKey, 'DateOfBirth', 'Year', 'Error'),
    month: dictionaryItem(errorKey, 'DateOfBirth', 'Month', 'Error'),
    day: dictionaryItem(errorKey, 'DateOfBirth', 'Day', 'Error'),
    age: dictionaryItem(errorKey, 'Age', 'Error'),
    telephone: dictionaryItem(errorKey, 'Telephone', 'Error'),
    all: dictionaryItem(errorKey, 'Generic', 'Error'),
  };

  const errorToDisplay = props.errors.length >= 3
    ? [{ type: ADD_MEMBER_ALL, message: errorMessages.all }] : props.errors;

  const getFormElementByName = (controlName) => formRef.current.querySelector(`[name="${controlName}"]`);

  const validateInput = (input) => {
    const inputId = input.name;
    const inputFieldName = input.dataset.fieldName;
    // Remove any preceding or trailing spaces from input field as this will fail validation
    const inputValue = input.value.trim();

    const isValid = inputValue;

    if (
      !inputValue
      && !props.errors.some(error => error.type === inputId)
      && (input.required && inputValue === '')
    ) {
      props.addErrors({ type: inputId, message: errorMessages[inputFieldName] });
    }
    if (isValid) {
      props.removeErrors(inputId);
    }
  };

  const validateDateComponentInput = (input) => {
    if (props.hideDob) return;
    const inputName = input.name;
    // Remove any preceding or trailing spaces from input field as this will fail validation
    const inputValue = input.value.trim();
    // Validate first name
    if (inputName === ADD_MEMBER_DATE_OF_BIRTH_YEAR) {
      if (
        !inputValue
        && !props.errors.some(error => error.type === ADD_MEMBER_DATE_OF_BIRTH_YEAR)
        && (input.required && inputValue === '')
      ) {
        props.addErrors({ type: ADD_MEMBER_DATE_OF_BIRTH_YEAR, message: errorMessages.year });
      }
      if (inputValue) {
        props.removeErrors(ADD_MEMBER_DATE_OF_BIRTH_YEAR);
      }
      // Validate lastname
    } else if (inputName === ADD_MEMBER_DATE_OF_BIRTH_MONTH) {
      if (
        !inputValue
        && !props.errors.some(error => error.type === ADD_MEMBER_DATE_OF_BIRTH_MONTH)
        && (input.required && inputValue === '')
      ) {
        props.addErrors({
          type: ADD_MEMBER_DATE_OF_BIRTH_MONTH,
          message: errorMessages.month,
        });
      }
      if (inputValue) {
        props.removeErrors(ADD_MEMBER_DATE_OF_BIRTH_MONTH);
      }
      // Validate dateOfBirth
    } else if (inputName === ADD_MEMBER_DATE_OF_BIRTH_DAY) {
      if (
        !inputValue
        && !props.errors.some(error => error.type === ADD_MEMBER_DATE_OF_BIRTH_DAY)
        && (input.required && inputValue === '')
      ) {
        props.addErrors({ type: ADD_MEMBER_DATE_OF_BIRTH_DAY, message: errorMessages.day });
      }
      if (inputValue) {
        props.removeErrors(ADD_MEMBER_DATE_OF_BIRTH_DAY);
      }
    }

    if (inputValue || (!input.required && inputValue === '')) {
      input.removeAttribute('data-error');
    } else {
      input.setAttribute('data-error', 'true');
    }
  };

  const validateTelephoneNumber = (input) => {
    const regex = new RegExp(/^\d+$/);
    const inputValue = input.value.trim();
    const isNumber = inputValue ? regex.test(inputValue) : true;

    if (inputValue && isNumber) {
      props.removeErrors(ADD_MEMBER_TELEPHONE_NUMBER);
    } else if (
      !props.errors.some(error => error.type === ADD_MEMBER_TELEPHONE_NUMBER)
      && (input.required && inputValue === '')
    ) {
      props.addErrors({ type: ADD_MEMBER_TELEPHONE_NUMBER, message: errorMessages.telephone });
    }
  };

  const validate = (controlName) => {
    if (controlName === ADD_MEMBER_FIRST_NAME || !controlName) {
      validateInput(getFormElementByName(ADD_MEMBER_FIRST_NAME));
    }

    if (controlName === ADD_MEMBER_LAST_NAME || !controlName) {
      validateInput(getFormElementByName(ADD_MEMBER_LAST_NAME));
    }

    if (controlName === ADD_MEMBER_DATE_OF_BIRTH_YEAR || !controlName) {
      validateDateComponentInput(getFormElementByName(ADD_MEMBER_DATE_OF_BIRTH_YEAR));
    }

    if (controlName === ADD_MEMBER_DATE_OF_BIRTH_MONTH || !controlName) {
      validateDateComponentInput(getFormElementByName(ADD_MEMBER_DATE_OF_BIRTH_MONTH));
    }

    if (controlName === ADD_MEMBER_DATE_OF_BIRTH_DAY || !controlName) {
      validateDateComponentInput(getFormElementByName(ADD_MEMBER_DATE_OF_BIRTH_DAY));
    }

    if (controlName === ADD_MEMBER_TELEPHONE_NUMBER || !controlName) {
      validateTelephoneNumber(getFormElementByName(ADD_MEMBER_TELEPHONE_NUMBER));
    }
  };

  const onTitleChange = (input) => {
    props.updateMember({
      ...props.member,
      fullName: {
        ...props.member.fullName,
        prefix: input.value,
      },
    });
  };

  const onInputChange = (input) => {
    const inputFieldName = input.dataset.fieldName;
    // Remove any preceding or trailing spaces from input field as this will fail validation
    const inputValue = input.value.trim();

    validate(inputFieldName);

    props.updateMember({
      ...props.member,
      fullName: {
        ...props.member.fullName,
        [inputFieldName]: inputValue,
      },
    });
  };

  const validateAge = (dateOfBirth) => {
    const validAdultAge = 18;
    const age = getAge(dateOfBirth);

    if (age < validAdultAge) {
      props.addErrors({ type: ADD_MEMBER_AGE, message: errorMessages.age });
    } else {
      props.removeErrors(ADD_MEMBER_AGE);
    }
  };

  const onDateOfBirthChange = (controlName) => {
    validateDateComponentInput(getFormElementByName(controlName));

    const yearInputValue = formRef.current.querySelector(`select[name="${ADD_MEMBER_DATE_OF_BIRTH_YEAR}"]`).value;
    const monthInputValue = formRef.current.querySelector(`select[name="${ADD_MEMBER_DATE_OF_BIRTH_MONTH}"]`).value;
    const dayInputValue = formRef.current.querySelector(`select[name="${ADD_MEMBER_DATE_OF_BIRTH_DAY}"]`).value;

    const monthString = monthInputValue !== '' ? padStart(parseInt(monthInputValue, 10) + 1, 2, '0') : '';
    const dayString = dayInputValue !== '' ? padStart(parseInt(dayInputValue, 10), 2, '0') : '';
    const dateString = [yearInputValue, monthString, dayString].join('-');

    if (yearInputValue && monthInputValue && dayInputValue) {
      validateAge(dateString);
    }

    props.updateMember({
      ...props.member,
      dateOfBirth: dateString,
    });
  };

  const onCountryPrefixChange = (input => {
    const inputValue = input.value.trim();
    const inputFieldName = input.dataset.fieldName;

    props.updateMember({
      ...props.member,
      contactNumber: {
        ...props.member.contactNumber,
        [inputFieldName]: inputValue,
      },
    });
  });

  const onTelephoneNumberChange = (input => {
    const inputValue = input.value.trim();
    const inputFieldName = input.dataset.fieldName;

    validate(input.name);

    props.updateMember({
      ...props.member,
      contactNumber: {
        ...props.member.contactNumber,
        [inputFieldName]: inputValue,
      },
    });
  });

  useImperativeHandle(ref, () => ({
    validate() {
      validate();
    },
  }));

  useEffect(() => {
    // we need to initialize the default non mandatory fields because the source of truth in
    // props may be completely empty. We have to create a single update for updateMember instead
    // of calling each update in series via onTitleChange and onCountryPrefixChange as these
    // are async and stale props will be feed to this component.
    if (props.isOpen) {
      const telephoneData = props.member?.contactNumber || {};
      const fullNameData = props.member?.fullName || {};

      const memberData = {
        ...props.member,
      };

      if (!props.hiddenFields.includes(ADD_MEMBER_TITLE)) {
        memberData.fullName = {
          ...fullNameData,
          prefix: getFormElementByName(ADD_MEMBER_TITLE).value,
        };
      }

      if (!props.hiddenFields.includes(ADD_MEMBER_TELEPHONE_NUMBER)) {
        memberData.contactNumber = {
          ...telephoneData,
          countryPrefix: getFormElementByName(ADD_MEMBER_COUNTRY_PREFIX).value,
        };
      }

      props.updateMember(memberData);
    }
  }, [props.isOpen]);

  const now = new Date();
  const previousYear = now.getFullYear() - 1;

  return (
    <div ref={formRef}>
      <Query query={GET_CONFIGURATION}>
        {({ data }) => (
          <>
            {!props.hiddenFields.includes(ADD_MEMBER_TITLE) &&
            <EditDetailsFields>
              <EditDetailsField label="Title" required={props.requiredFields.includes(ADD_MEMBER_TITLE)}>
                <Select
                  data-field-name="title"
                  name={ADD_MEMBER_TITLE}
                  value={props.member?.fullName?.prefix ? props.member?.fullName?.prefix : 'Mr'}
                  onChange={(e) => onTitleChange(e.currentTarget)}
                  required={props.requiredFields.includes(ADD_MEMBER_TITLE)}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Mstr">Mstr</option>
                </Select>
              </EditDetailsField>
              <EditDetailsField label="First Name" required={props.requiredFields.includes(ADD_MEMBER_FIRST_NAME)}>
                <Input
                  data-field-name="firstName"
                  name={ADD_MEMBER_FIRST_NAME}
                  type="text"
                  placeholder="First Name"
                  value={props.member?.fullName?.firstName ? props.member?.fullName?.firstName : ''}
                  onChange={(e) => onInputChange(e.currentTarget)}
                  error={props.errors.some(err => err.type === ADD_MEMBER_FIRST_NAME)}
                  onBlur={(e) => validateInput(e.currentTarget)}
                  required={props.requiredFields.includes(ADD_MEMBER_FIRST_NAME)}
                />
              </EditDetailsField>
              <EditDetailsField label="Last Name" required={props.requiredFields.includes(ADD_MEMBER_LAST_NAME)}>
                <Input
                  data-field-name="surname"
                  name={ADD_MEMBER_LAST_NAME}
                  placeholder="Last Name"
                  value={props.member?.fullName?.surname ? props.member?.fullName?.surname : ''}
                  onChange={(e) => onInputChange(e.currentTarget)}
                  error={props.errors.some(err => err.type === ADD_MEMBER_LAST_NAME)}
                  onBlur={(e) => validateInput(e.currentTarget)}
                  required={props.requiredFields.includes(ADD_MEMBER_LAST_NAME)}
                />
              </EditDetailsField>
            </EditDetailsFields>}
            {!props.hideDob &&
            <EditDetailsFields>
              <EditDetailsField label="Date of birth" required={props.requiredFields.includes(ADD_MEMBER_DATE_OF_BIRTH_YEAR)}>
                <>
                  <DayPicker
                    name={ADD_MEMBER_DATE_OF_BIRTH_DAY}
                    classes="customSelect"
                    defaultValue="Day"
                    year={year || previousYear}
                    month={month || 0}
                    required={props.requiredFields.includes(ADD_MEMBER_DATE_OF_BIRTH_DAY)}
                    value={day}
                    onChange={(e) => onDateOfBirthChange(ADD_MEMBER_DATE_OF_BIRTH_DAY)}
                    onBlur={e => validateDateComponentInput(e.currentTarget)}
                  />
                  <MonthPicker
                    name={ADD_MEMBER_DATE_OF_BIRTH_MONTH}
                    classes="customSelect"
                    defaultValue="Month"
                    short
                    year={year || previousYear}
                    required={props.requiredFields.includes(ADD_MEMBER_DATE_OF_BIRTH_MONTH)}
                    value={month}
                    onChange={(e) => onDateOfBirthChange(ADD_MEMBER_DATE_OF_BIRTH_MONTH)}
                    onBlur={e => validateDateComponentInput(e.currentTarget)}
                  />
                  <YearPicker
                    name={ADD_MEMBER_DATE_OF_BIRTH_YEAR}
                    classes="customSelect"
                    defaultValue="Year"
                    reverse
                    required={props.requiredFields.includes(ADD_MEMBER_DATE_OF_BIRTH_YEAR)}
                    value={year}
                    onChange={(e) => onDateOfBirthChange(ADD_MEMBER_DATE_OF_BIRTH_YEAR)}
                    onBlur={e => validateDateComponentInput(e.currentTarget)}
                  />
                </>
              </EditDetailsField>
            </EditDetailsFields>
            }
            {!props.hiddenFields.includes(ADD_MEMBER_TELEPHONE_NUMBER) &&
            <EditDetailsFields>
              <EditDetailsField label="Telephone Number" required={props.requiredFields.includes(ADD_MEMBER_TELEPHONE_NUMBER)}>
                <SelectCountryPrefixStyled
                  data-field-name="countryPrefix"
                  name={ADD_MEMBER_COUNTRY_PREFIX}
                  onChange={(e) => onCountryPrefixChange(e.currentTarget)}
                  value={props.member?.contactNumber ?
                    props.member.contactNumber.countryPrefix : 44}
                  required={props.requiredFields.includes(ADD_MEMBER_COUNTRY_PREFIX)}
                >
                  {data?.configuration?.internationalPhoneAccessCodes?.map(x => (
                    <option key={x} value={x}>+{x}</option>
                  ))}
                </SelectCountryPrefixStyled>
                <InputNumberStyled
                  data-field-name="number"
                  value={props.member?.contactNumber?.number ? props.member.contactNumber?.number : ''}
                  error={props.errors.some(err => err.type === ADD_MEMBER_TELEPHONE_NUMBER)}
                  id="number"
                  inputMode="numeric"
                  name={ADD_MEMBER_TELEPHONE_NUMBER}
                  onChange={(e) => onTelephoneNumberChange(e.currentTarget)}
                  onBlur={(e) => validateTelephoneNumber(e.currentTarget)}
                  placeholder="Mobile number"
                  type="text"
                  required={props.requiredFields.includes(ADD_MEMBER_TELEPHONE_NUMBER)}
                  inline
                />
              </EditDetailsField>
            </EditDetailsFields>}
          </>
        )}
      </Query>
      {errorToDisplay.map(err => err && (
        <MessageWarning
          id="Checkout_Error"
          key={err.message}
          dictionary={err.message}
        />
      ))}
    </div>
  );
});

AddMemberForm.propTypes = propTypes;
AddMemberForm.defaultProps = defaultProps;

// do not add compose here, as it will break the forwardRef needed to externally validate form.
export default AddMemberForm;
