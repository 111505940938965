import styled from 'styled-components';

import { below } from '../../../styles/libs';

const EditDetailsFields = styled.div`
  display: flex;

  & + & {
    margin-top: 1rem;
  }

  ${({ theme }) => below(theme.MOBILE_LARGE)`
    flex-direction: column;
  `};

`;

export default EditDetailsFields;
