import styled, { css } from 'styled-components';
import { Input as PreInput, Select as PreSelect } from '../../../ui/Form';

export const FormField = styled.div`
  display: flex;
  align-items: center;
`;

export const Fieldset = styled.fieldset`
  min-inline-size: auto;
  padding: 0;
  margin: 0;
  border: 0;
`;

export const Input = styled(PreInput)`
  width: 100%;
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;

  ${({ inline }) => inline && css`
    width: auto;
  `}
`;

export const Select = styled(PreSelect)`
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

export default null;
