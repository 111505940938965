export function parseDateString(str) {
  let dateString = str;
  if (!dateString) {
    dateString = '--';
  }
  const components = dateString.split('-');
  return {
    year: components[0] ?? '',
    month: (components[1] !== '') ? parseInt(components[1], 10) - 1 : '',
    day: (components[2] !== '') ? parseInt(components[2], 10) : '',
  };
}

export function parseSortCodeString(str) {
  let sortCodeString = str;
  if (!sortCodeString) {
    sortCodeString = '--';
  }
  const components = sortCodeString.split('-');
  return [
    components[0] ?? '',
    components[1] ?? '',
    components[2] ?? '',
  ];
}

export function mapUserDataToMemberData(user) {
  const { __typename, ...address } = user.address;
  return {
    lead: {
      address,
      contactNumber: {
        ...user.telephones?.[0],
      },
      dateOfBirth: user.dateOfBirth,
      emailAddress: user.email,
      fullName: {
        prefix: user.title,
        firstName: user.firstName,
        surname: user.lastName,
      },
      isAlternativeContactNumber: !user.telephones?.length,
      statementBestDescription: 'statementBestDescription',
      alternateOnly: !user.telephones?.length,
    },
  };
}

export const numberOnly = (str) => str.replace(/\D/g, '');
