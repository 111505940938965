import styled from 'styled-components';
import { ButtonBrand } from '../Button';
import { ModalContent } from '../Modal';

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 0rem 1.5rem;
  margin: -1.5rem -1.5rem 1.5rem;
  height: 4.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 1.5rem 1.5rem;
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
  border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const ModalContentStyled = styled(ModalContent)`
  padding: 1.5rem;
  max-width: 600px;

`;

export const SaveButton = styled(ButtonBrand)`
  padding: 0.5rem 1.75rem;
`;
