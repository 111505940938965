import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Label from '../Form/Label';
import { below } from '../../../styles/libs';

const propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  required: PropTypes.bool,
  small: PropTypes.bool,
};

const defaultProps = {
  label: '',
  required: false,
  small: false,
};

const Field = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-grow: 1;

  ${({ small }) => small && css`
    max-width: 90px;
  `}

  & + & {
    margin-left: 1rem;

    ${({ theme }) => below(theme.MOBILE_LARGE)`
      margin-left: 0;
      margin-top: 1rem;
    `};
  }
`;

const FieldLabel = styled(Label)`
  ${({ theme, required }) => required && css`
    &:after {
      content: '*';
      position: relative;
      display: inline-block;
      color: ${theme.COLOR_LAKE_GREEN_ORIGINAL};
      transform: translateX(0.25em) scale(1.5);
    }
  `}
`;

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > select,
  & > input {
    flex-grow: 1;
  }

  & > select[name="countryPrefix"] {
    flex-grow: 0;
  }
`;

function EditDetailsField(props) {
  return (
    <Field small={props.small}>
      <FieldLabel required={props.required}>
        {props.label}
      </FieldLabel>
      <Controls>
        {props.children}
      </Controls>
    </Field>
  );
}

EditDetailsField.propTypes = propTypes;
EditDetailsField.defaultProps = defaultProps;

export default EditDetailsField;
