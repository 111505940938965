import styled from 'styled-components';
import { Input as PreInput, Select as PreSelect } from '../../../ui/Form';
import { MessageWarning as MW } from '../../../ui/Message';

export const MessageWarning = styled(MW)`
  margin: 1rem auto 0;
  width: 100%;
`;

export const Select = styled(PreSelect)`
  width: 100%;
`;

export const Input = styled(PreInput)`
    width: 100%;
`;

export const DateInput = styled(PreInput)`
    width: 100%;
    text-transform: uppercase;
`;

export default null;
